<template>
    <custom-table
        :title="$t('pages.developer.storage.title')"
        :subTitle="$t('pages.developer.storage.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:capacity="{ row: record }">
            {{ sprintf('%s (%s)', [record.size_detail.text, record.real_size_detail.text])}}
        </template>
        <template v-slot:progress="{ row: record }">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="record.usage_percentage"/>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_storage" ref="storageModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="storageForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.storage.cols.name') }}</label>
                                <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.name" type="text"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.storage.cols.size') }}</label>
                                <el-form-item prop="size" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.size" v-mask="{ alias: 'integer', allowMinus:false, placeholder: '', rightAlign: false}" class="input-with-select">
                                        <template #append>
                                            <el-form-item prop="unit" :rules="$validation.getMessage(['required'])">
                                                <el-select v-model="form.data.unit" :placeholder="$t('common.chooseSelect')" style="width: 150px">
                                                    <el-option v-for="(unit, unitIndex) in sizeUnits" :key="unitIndex" :value="unit" :label="unit">{{ unit }}</el-option>
                                                </el-select>
                                            </el-form-item>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.storage.cols.path') }}</label>
                                <el-form-item prop="path" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.path" type="text"/>
                                </el-form-item>
                                <div class="alert alert-warning d-flex align-items-center p-5 mb-10" v-if="form.data.path && form.data.path.trim().length">
                                    <div class="d-flex flex-column">
                                        <span v-html="sprintf($t('pages.developer.storage.warnings.path'), [form.data.path, form.data.path])"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.developer.storage.cols.name"),
                    key: "name"
                },
                {
                    name: this.$t("pages.developer.storage.cols.path"),
                    key: "path"
                },
                {
                    name: this.$t("pages.developer.storage.cols.capacity"),
                    scopedSlots: {customRender: "capacity"}
                },
                {
                    name: this.$t("pages.developer.storage.cols.used"),
                    key: "used_size_detail.text"
                },
                {
                    name: this.$t("pages.developer.storage.cols.progress"),
                    scopedSlots: {customRender: "progress"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            sizeUnits: ['GB', 'TB', 'PB']
        }
    },
    computed: {
        table() {
            return this.$store.state.developer.storage.table;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.developerManagement.storage"), [this.$t("menu.developerManagement.title")]);
        this.$store.dispatch('developer/storage/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
    },
    methods:{
        newRecord(){
            this.form.updateStatus = false;
            this.form.data = {};
            this.form.title = this.$t("pages.developer.storage.new");
            this.showModal(this.$refs.storageModal);
        },
        fetchRecord(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.developer.storage.edit");
            this.axios.get(this.endpoint.get('storage', 'developer') + '/' + record.id).then(response => {
                let data = response.data.data;

                if(data.size_detail) {
                    data.unit = data.size_detail.unit;
                    data.size = data.size_detail.size;
                }

                this.form.data = data;
                this.showModal(this.$refs.storageModal);
            });
        },
        onSubmit(){
            this.$refs.storageForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoint.get('storage', 'developer') + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.storageModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoint.get('storage', 'developer'), this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.storageModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("developer/storage/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("developer/storage/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("developer/storage/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style>
.input-with-select .el-input-group__append .el-form-item__content {
    line-height: 38px !important;
}
.input-with-select .el-input-group__append .el-select .el-input__inner{
    height: 38px !important;
    line-height: 38px !important;
}
</style>